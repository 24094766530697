<template>
    <div class="common-wrap">
      <headNav active="1"></headNav>
      <showHeader :showHeaderIndex="5" @showHeaderChange="showHeaderChange" />
        <div class="common-contents" v-if="$i18n.locale == 'zh'">
            <div class="contents">
                <div class="bg"></div>
                <div class="block tal w1200">
                    <img class="contents-img" src="../assets/version2024/rb-1.png" alt="" />
                    <img class="contents-img mt-140" src="../assets/version2024/rb-2.png" alt="" />
                    <img class="contents-img mt-105" src="../assets/version2024/rb-3.png" alt="" />
                    <img class="contents-img mt-105" src="../assets/version2024/rb-4.png" alt="" />
                    <img class="contents-img mt-105" src="../assets/version2024/rb-5.png" alt="" />
                    <img class="contents-img mt-105" src="../assets/version2024/rb-6.png" alt="" />
                </div>
            </div>
            <div class="contents-bot mt-60">
                <div class="w1200">
                    <img class="contents-img" src="../assets/version2024/rb-7.png" alt="" />
                </div>
            </div>
        </div>
        <div class="common-contents" v-else>
            <div class="contents">
                <div class="bg"></div>
                <div class="block tal w1200">
                    <img class="contents-img" src="../assets/version2024/rbEn-1.png" alt="" />
                    <img class="contents-img mt-140" src="../assets/version2024/rbEn-2.png" alt="" />
                    <img class="contents-img mt-105" src="../assets/version2024/rbEn-3.png" alt="" />
                    <img class="contents-img mt-105" src="../assets/version2024/rbEn-4.png" alt="" />
                    <img class="contents-img mt-105" src="../assets/version2024/rbEn-5.png" alt="" />
                    <img class="contents-img mt-105 mb-50" src="../assets/version2024/rbEn-6.png" alt="" />
                </div>
            </div>
            <!-- <div class="contents-bot mt-60">
                <div class="w1200">
                    <img class="contents-img" src="../assets/version2024/rb-7.png" alt="" />
                </div>
            </div> -->
        </div>
      <footNav />
    </div>
  </template>
  
  <script>
  import headNav from "../components/header2.vue";
  import footNav from "../components/footer2.vue";
  import showHeader from "../components/showHeader.vue";
  
  export default {
    components: { headNav, footNav, showHeader },
    data() {
      return {
        currentData: {},
        currentShowHeaderIndex: 5, // tabs 切换index
      };
    },
    mounted() {
     
    },
    methods: {
      // tabs 切换
      showHeaderChange(index) {
        if (index != 5) {
            if (index == 3) {
                this.$router.push({ name: "usshow" });
            } else if (index == 4) {
                this.$router.push({ name: "europe" });
            } else  {
                this.$router.push({ name: "othershow", query: { type: index } });
            }
        }
      }
    },
  };
  </script>
  
  <style scoped lang="scss">
  @keyframes anim {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .common-contents {
    background-color: #f2f6f7;
    .contents {
      position: relative;
      .bg {
        position: absolute;
        right: 0;
        z-index: 10;
        background-size: 100% 100%;
        width: 414px;
        height: 619px;
        background-image: url("../assets/version2/show-bg-2.png");
        top: 1500px;
      }
      .block {
        position: relative;
       
        .title-1 {
          font-size: 40px;
          font-family: "Microsoft YaHei";
          color: #260f02;
        //   letter-spacing: 2px;
          font-weight: 500;
        }
        .title-1-en {
          font-size: 40px;
          letter-spacing: 0px;
        }
        .line {
          width: 100%;
          background: #c7c1bd;
        }
        .line-1 {
          height: 4px;
        }
        .line-2 {
          height: 2px;
          margin-top: 5px;
        }
        .title-en {
          font-size: 96px;
          font-weight: bolder;
          -webkit-text-stroke: 1px rgb(196, 196, 196);
          color: transparent;
          font-family: Roboto, Roboto-Regular;
        }
      }
   
    
    }
  }
  .contents-img{
        width: 100%;
    }
    .contents-bot{
        width: 100%;
        background: #f9fdfe;
        padding:47px 0 67px;
    }
  </style>
 